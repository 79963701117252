<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="true"
    :right="$vuetify.rtl"
    src="/img/SidebarBackground.png"
    :mini-variant="mini"
    mini-variant-width="80"
    style="background-position: 42% 100%;"
    app
    width="260"
  >
    <template
      #img="props"
    >
      <v-img
        v-bind="props"
      />
    </template>

    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3" />

      <default-account-settings />

      <v-divider class="mx-3 mb-2" />

      <default-list :items="items" />

    </div>

    <template #append>
      <div class="pa-4 text-center" />
    </template>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'DefaultDrawer',

    components: {
      DefaultAccountSettings: () => import(
        /* webpackChunkName: "default-account-settings" */
        './widgets/AccountSettings'
      ),
      DefaultDrawerHeader: () => import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
      DefaultList: () => import(
        /* webpackChunkName: "default-list" */
        './List'
      ),
    },

    data () {
      return {
        items: [
          {
            title: 'Overview',
            icon: 'mdi-view-dashboard',
            to: '/',
          },
          {
            title: 'Progression',
            icon: 'mdi-clipboard-text',
            to: '/progression',
          },
          {
            title: 'Stashes',
            icon: 'mdi-inbox-multiple',
            to: '/gather',
          },
          {
            title: 'Atlas',
            icon: 'mdi-globe-model',
            to: '/hideout',
          },
          {
            title: 'Contributors',
            icon: 'mdi-glass-mug-variant',
            to: '/contributors',
          },
          {
            title: 'Settings',
            icon: 'mdi-cogs',
            to: '/settings',
          },
        ],
      }
    },

    mounted () {
      // if (this.myselfQuestAvailable(this.questDictionary['Collector']) === 0) {
      //   this.items[3].items.push({title: 'Fence', to: '/trader/fence', image: '/img/FenceHeadshot.jpg'})
      // }
    },

    computed: {

      ...sync('app', [
        'mini',
        'dark',
        'drawer',
      ]),
    },
  }
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
